import React from "react"

import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

//===============COMPONENT_NAVBAR===============

export default function Footer() {
  return (
    <div className="Footer">
      <FooterComponent />
    </div>
  )
}

class FooterComponent extends React.Component {
  render() {
    return (
      <div className="Footer-Content-Grid">
        <div className="Footer-Content-Grid-Item">
          <CompanySection />
        </div>
        {/*<div className="Footer-Content-Grid-Item">
          <LegalSection />
        </div>*/}
        <div className="Footer-Content-Grid-Item">
          <ContactSection />
        </div>
      </div>
    )
  }
}

function CompanySection() {
  return (
    <>
      <Copyright />
    </>
  )
}

function LegalSection() {
  return (
    <>
      <a
        className="Footer-Contact-Link"
        href="https://www.altergottwebdesign.com/"
      >
        altergottwebdesign.com
    </a>
    </>
  )
}

function ContactSection() {
  return (
    <>
      <div>
        <a
          className="Footer-Contact-Link"
          href="mailto:oliver.altergott@yahoo.com"
        >
          oliver.altergott@yahoo.com
    </a>
      </div >
    </>
  )
}

class Copyright extends React.Component {
  render() {
    return (
      <div className="Copyright-Text">
        <small>&copy;2021 Oliver Altergott</small>
      </div>
    )
  }
}

function FooterLink(props) {
  return (
    <div className="Footer-Link">
      <Link
        className="Footer-Link-Router"
        to={props.link}
      >
        {props.label}
      </Link>
    </div>
  )
}
