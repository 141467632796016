import React from "react"
import { Link } from "gatsby"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"


//===============COMPONENT_NAVBAR===============

export default function MenuNavbar() {
  return (
    <div className="MenuNavbar">
      <BootstrapNavbar />
    </div>
  )
}

class BootstrapNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.scrollToTop = this.scrollToTop.bind(this)
    this.setNavExpanded = this.setNavExpanded.bind(this)
    this.closeNavBar = this.closeNavBar.bind(this)
    this.closeNavAndScroll = this.closeNavAndScroll.bind(this)
    this.state = {
      navExpanded: false,
    }
  }

  componentDidMount() {
    var self = this
    this.closeNav = function () {
      console.log("closeNav")
      self.setState({ navExpanded: false })
    }
  }

  setNavExpanded(expanded) {
    this.setState({ navExpanded: expanded })
  }

  closeNavAndScroll(e, param) {
    console.log("closeNav")
    this.setState({ navExpanded: false })
    if (scrollTo(param, "start", 65)) { e.preventDefault(); console.log("preventDefault"); }
  }

  closeNavBar() {
    console.log("closeNavBar")
    this.setState({ navExpanded: false })
  }

  scrollToTop() { }

  componentWillUnmount() { }

  render() {
    return (
      <div className="BootstrapNavbar">
        <Navbar
          className="BootstrapNavbar-Navbar"
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          fixed="top"
          onToggle={this.setNavExpanded}
          expanded={this.state.navExpanded}
        >
          <Nav className="Navbar-Item-Brand-center">
            <Link
              className="Navbar_HashLink"
              to="/"
              onClick={this.closeNavBar}
            >
              Home
              </Link>
          </Nav>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Link
                className="Navbar_HashLink"
                to="/blog"
                onClick={this.closeNavBar}
              >
                Blog
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div >
    )
  }
}

const scrollTo = (selector, blockPosition = "start", offset = 65) => {
  const element = document.querySelector(selector)

  if (element) {
    const top = window.scrollY + element.getBoundingClientRect().top - offset
    window.scrollTo({
      top: top,
      behavior: "smooth",
    })

    return true;
  } else { return false; }
}
